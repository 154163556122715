
import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';
import { FetchPolicy } from 'apollo-client';
import Message from '@/components/mixins/Message.vue';
import EventBus from '@/event-bus';
import BaseChart from '@/components/Chart/BaseChart.vue';
import MenuMapper from '@/helpers/mappers/menu-mapper';
import { NavigationLink } from '@/helpers/mappers/mapper';
import Tooltip from '@/components/Tooltip/Tooltip.vue';
import Icon from '@/components/commonComponents/Icon.vue';
import { isEmptyArray } from '@/helpers/arrayHelpers';
import ListBulkActions from '@/components/commonComponents/ListBulkActions.vue';
import downloadBase64 from '@/helpers/files';

// Lazy loading imports
const Tags = () => import(
  /* webpackChunkName: "tags" */
  /* webpackPrefetch: false */
  '@/components/commonComponents/Tags.vue'
);
const Assignments = () => import(
  /* webpackChunkName: "assignments" */
  /* webpackPrefetch: false */
  '@/components/commonComponents/Assignments.vue'
);
const ActionButton = () => import(
  /* webpackChunkName: "action-button" */
  /* webpackPrefetch: false */
  '@/components/Button/Button.vue'
);

interface Attachment {
  name: string,
  base64Content: string,
}

interface ModuleData {
  '__typename': string,
}

interface WorkflowTransitionButton {
  name: string,
  workflowId: number,
  label?: string,
  successMessage: string,
  errorMessage: string,
  metadata?: unknown,
}

@Component({
  name: 'HeaderBlock',
  methods: { isEmptyArray },
  components: {
    ListBulkActions,
    Icon,
    Tooltip,
    Tags,
    Assignments,
    ActionButton,
    BaseChart,
  },
})
export default class HeaderBlock extends Vue {
  @Prop({ required: true })
  private readonly blockId!: number;

  @Prop({ required: true })
  private readonly dossierId!: number;

  @Prop({ required: true })
  private readonly dossierTypeSlug!: string;

  @Prop({ required: true })
  private readonly navigationSlug!: string;

  @Prop()
  private readonly context?: string;

  @Prop()
  private readonly title?: string;

  protected loading = false;

  protected contextMenu: Array<NavigationLink> = [];

  protected workflowTransitionButtons: Array<WorkflowTransitionButton> = [];

  protected header = {
    title: '',
    subTitle: '',
    status: {
      label: undefined,
    },
    tags: undefined,
    tagOptions: undefined,
    tagCreateLabel: undefined,
    applyTagToDossier: undefined,
  };

  protected assignments: { [key: string]: unknown } = {
    assigned: [],
    assignable: [],
  };

  mounted(): void {
    this.fetchData('no-cache');

    const forceRerender = () => this.fetchData('network-only');

    EventBus.$on('forceRerender', forceRerender);

    this.$once('hook:beforeDestroy', () => {
      EventBus.$off('forceRerender', forceRerender);
    });
  }

  // eslint-disable-next-line class-methods-use-this
  public replaceHashes(str: string): string {
    // This regex captures the first '#' and all characters up to the next '#'
    return str.replace(/#(.*)/, (_, p1) => `#${p1.replace(/#/g, '|')}`);
  }

  private transitionById(workflow: WorkflowTransitionButton): void {
    this.loading = true;
    const {
      workflowId, name: transitionName, successMessage, errorMessage, metadata,
    } = workflow;

    const variables = {
      dossierId: this.dossierId,
      dossierTypeSlug: this.dossierTypeSlug,
      navigationSlug: this.navigationSlug,
      workflowId,
      transitionName,
    };

    import('@/graphql/queries/dossier-workflow-transition-dossier')
      .then(({ default: mutation }) => this.$apollo.mutate({
        mutation,
        variables,
      }))
      .then((response) => {
        const attachments = response.data.dossierWorkflowTransitionDossier?.metadata?.attachments;

        return this.downloadAttachments(attachments);
      })
      .then(() => {
        Message.success(successMessage);
      })
      .then(() => {
        this.fetchData('network-only');
        EventBus.$emit('sidebar:update');

        if ((metadata as { forceRerender: boolean }).forceRerender) {
          EventBus.$emit('initializeForceRerender');
        }
      })
      .catch((error) => {
        Message.error(errorMessage);
        throw error;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private downloadAttachments(attachments: Array<Attachment>): Promise<void> {
    this.loading = true;
    return new Promise((resolve) => {
      if (attachments.length > 0) {
        attachments.forEach((attachment: Attachment) => {
          downloadBase64(attachment);
        });
      }
      resolve();
    });
  }

  private mapWorkflowTransitionButton(workflowTransitionButtons: Array<WorkflowTransitionButton>): Array<WorkflowTransitionButton> {
    const mapper = (button: WorkflowTransitionButton) => ({ ...button, dossierIds: [this.dossierId] });
    return workflowTransitionButtons.map(mapper);
  }

  private fetchData(fetchPolicy: FetchPolicy = 'cache-first'): void {
    this.loading = true;

    const variables = {
      dossierId: this.dossierId,
      blockId: this.blockId,
      context: this.context,
      dossierTypeSlug: this.dossierTypeSlug,
      navigationSlug: this.navigationSlug,
    };

    import('@/graphql/queries/header-block')
      .then(({ default: query }) => this.$apollo.query({
        fetchPolicy,
        query,
        variables,
      }))
      .then((response) => {
        this.header.title = response.data.header.title;
        this.header.subTitle = response.data.header.subTitle;
        this.header.tags = response.data.header.tags;
        this.header.status = response.data.header.status;

        const { items } = response.data.contextMenu;
        const menuMapper = new MenuMapper();
        this.contextMenu = menuMapper.map(items);

        this.header.tagCreateLabel = response.data.header.metadata.find(
          ({ __typename: typename }: ModuleData) => typename === 'TagMetadata',
        ).tag_create_label;
        this.header.applyTagToDossier = response.data.header.metadata.find(
          ({ __typename: typename }: ModuleData) => typename === 'TagMetadata',
        ).apply_tag_to_dossier;

        this.workflowTransitionButtons = response.data.workflowTransitionButtons;

        this.assignments.assigned = response.data.header.assigned;
        this.assignments.assignable = response.data.header.metadata.find(
          ({ __typename: typename }: ModuleData) => typename === 'AssignmentMetadata',
        ).assignable;
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      })
      .finally(() => {
        this.loading = false;
        this.$emit('rendered');
      });
  }
}
